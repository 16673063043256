/* eslint-disable react-hooks/exhaustive-deps */
// import external
import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-enterprise';
import {useDispatch, useSelector} from "react-redux";
// import external styles
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
// import assets

import {
    AG_GRID_LOCALE_FR,
    AG_GRID_LOCALE_DE,
    AG_GRID_LOCALE_EN,
    AG_GRID_LOCALE_IT
} from "../../assets/i18n/AGGridLabels";
import {ReactComponent as DownloadIcon} from "../../assets/svg/icon-download.svg";
import {ReactComponent as MagnifierIcon} from "../../assets/svg/icon-magnifier.svg";

// import internal styles
import "./_style.scss"
import "./_grid-style.scss"
// import components
import {dataPassesFilter} from "./FilterUtil/FilterTypes";
import {
    saveCurrentPageToRedux,
    setColumnFilterModel,
    setExternalActiveFilter
} from "../../redux/stateSlice/gridSlice";
import ButtonWithIcon from "../ButtonWithIcon";
import GridColumnFilterList from "./GridColumnFilterList";
import PropTypes from "prop-types";
import TextTooltip from "./TextTooltip/TextTooltip";
import FilterButtonGroup from "../FilterButtonGroup";
import {defaultCellRenderer} from "./CellRenderers/defaultCellRenderer";
import {GRID_SESSION_STORAGE} from "../../actions/constants/gridSessionStorage.constants";
import {getFromSessionStorage, saveToSessionStorage} from "../../actions/storage.actions";
import {triggerEvent} from "../../actions/eventTracking.actions";

/**
 *go
 * @param {*} props
 * columnDefs: definition of columns to show
 * data: all the data to show
 * downloadable: true | false if data should be downloadable
 * title: Title that is shown above of grid (optional)
 * subtitle: Subtitle that is shown below the title (optional)
 * @returns layout of the GridHandler
 */

const GridHandler = memo( (props) => {

    GridHandler.propTypes = {
        columnDefs: PropTypes.array.isRequired, // array of columns
        data: PropTypes.array.isRequired, // array of the data that fills the grid
        downloadable: PropTypes.bool.isRequired, // true | false if data should be downloadable
        gridTitle: PropTypes.object, //Used to place a GridTitle object above the grid
        hidePagination: PropTypes.bool, // true | false if pagination should be hidden
        hideSearch: PropTypes.bool, // true | false if search should be hidden
        headerContent: PropTypes.object, // If you want to place an object above the grid content, use this (e.g. a title)
        gridHeight: PropTypes.string, //Uses a fixed height for the grid if one is specified here
        filterItems: PropTypes.array, // Array of FilterButtonItem objects
        defaultFilterType: PropTypes.string, //Index of the default filter item to use,
        gridName:PropTypes.string.isRequired, // gridName is required for saving filter, sorting, external filter, column order etc.
        forwardedRef: PropTypes.object, //If you want to use the gridRef outside of the GridHandler, pass it using this
        onFilterChanged: PropTypes.func, //Function to be called (in addition to what is currently called) when a grid filter is changed
        notHideUnusedCol: PropTypes.bool // provide the option to hide UnusedColumn. By default, we hide
    }


    // Use null or props.forwardedRef to use the gridRef
    let gridRef;

    if(props.forwardedRef){
        gridRef = props.forwardedRef;
    }
    else{
        // eslint-disable-next-line react-hooks/rules-of-hooks
        gridRef = useRef(null);
    }


    const dispatch = useDispatch()
    // ------ localization
    const current_language = useSelector(state => state.labels.currentLanguage)
    //const non_grid_labels = useSelector(state => state.labels.currentLabels) Currently not needed since we handle translations outside of GridHandler
    const localeText = useMemo(() => {
        switch (current_language) {
            case "FR":
                return AG_GRID_LOCALE_FR
            case "EN":
                return AG_GRID_LOCALE_EN
            case "IT":
                return AG_GRID_LOCALE_IT
            default:
                return AG_GRID_LOCALE_DE
        }
    }, [current_language]);

    // define the options for the grid
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            // by setting the filter to be true, the default filter used is the set filter
            filter: true,
            resizable: true,
            filterParams: {
                buttons: ['apply', 'reset'],
                closeOnApply: true,
            },
            cellRenderer: defaultCellRenderer,
            tooltipComponent: TextTooltip,
            tooltipValueGetter: (params) => {
                return params.value;
            }
        };
    }, []);

    const hideEmptyColumns = (notHideUnusedCol) => {
        let allColumnIds = [];
        //All data nodes of the grid
        let rowNodes = [];
        gridRef?.current?.api?.forEachNode((node) => {
            rowNodes.push(node);
        })
        gridRef.current?.columnApi?.getColumns().forEach((column) => {
            // Only push the column to the array if it is supposed to be auto resized
            if (!column.getUserProvidedColDef().disableAutoResizing){
                allColumnIds.push(column.getId())
            }
            //Check for each column if it is empty (only has null/undefined/empty string values)
            let columnIsEmpty =false
            // by default (when hideUnusedColumn is not defined), we need to hide
            if(!notHideUnusedCol){
                columnIsEmpty = !rowNodes.some(rowNode => {
                    const rowValue = gridRef.current?.api?.getValue(column, rowNode);
                    return rowValue !== null && rowValue !== undefined && rowValue !== '';
                })
            }
            // Only auto hide columns, if column visibility is not explicitly defined in column definitions
            if(column.getUserProvidedColDef().hide === undefined) {
                // hide the edit buttons
                if(column.getColId().includes("periodEditor") && !column.getUserProvidedColDef().hasPermission){
                    column.setVisible(false);
                }else{
                    //If column is empty AND NOT an icon column, hide it
                    column.setVisible(!columnIsEmpty || column.getColId().includes("icon"));
                }
            }
        });
        // Resize all columns to fit the content
        gridRef.current?.columnApi?.autoSizeColumns(allColumnIds, false);
    }


    const gridOptions = {
        // when there are less or equals 15 data entries make grid autoHeight otherwise show normal height
        // this is mainly (also) a performance aspect. Ag-grid loads slow with a huge amount of data.
        // domLayout: props.data.length <= 15 ? 'autoHeight' : 'normal'
        domLayout: props.gridHeight ? 'normal' : 'autoHeight',
        pagination: props.hidePagination ? false : true,
        paginationPageSize: 30,
        animateRows: true,
        tooltipShowDelay: 500,
        onFirstDataRendered: hideEmptyColumns(props.notHideUnusedCol),
    };
    // -----quick filter (global search)-----
    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current?.api?.setQuickFilter(
            document.getElementById("quick-filter-text-box " + props.gridName).value
        );
    }, []);

    //--- Required for Custom Pagination Controls ---
    const [currentPage, setCurrentPage] = useState(1);
    // Go to next page
    const nextPageClick = useCallback(() => {
        gridRef.current?.api?.paginationGoToNextPage();
    }, []);
    // Go to previous page
    const previousPageClick = useCallback(() => {
        gridRef.current?.api?.paginationGoToPreviousPage();

    }, []);
    const onPaginationChanged = useCallback(() => {
        setCurrentPage(gridRef.current?.api?.paginationGetCurrentPage() + 1);
        dispatch(saveCurrentPageToRedux({
            gridName: props.gridName,
            pageNumber: gridRef.current?.api?.paginationGetCurrentPage()
        }))
        updatePaginationButtons();
    }, []);
    // So page doesn't crash on re-render
    let totalPages;
    if (gridRef.current) {
        totalPages = gridRef.current?.api?.paginationGetTotalPages();
        //If there are no pages, just set it to one (so it doesn't display "Page 1 of 0")
        if(!totalPages){
            totalPages = 1
        }
    }

    const [nextButtonIsDisabled, setNextButtonIsDisabled] = useState(false);
    const [previousButtonIsDisabled, setPreviousButtonIsDisabled] = useState(false);

    //Checks if pagination buttons should be disabled
    const updatePaginationButtons = () => {
        if (gridRef.current) {
            //If button is the "Next Button" and we are on last page => Disable
            if (gridRef.current?.api?.paginationGetCurrentPage() + 1 === gridRef.current?.api?.paginationGetTotalPages()) {
                setNextButtonIsDisabled(true);
            } else {
                setNextButtonIsDisabled(false);
            }
            //If button is the "Previous Button" and we are on the first page => Disable
            if (gridRef.current?.api?.paginationGetCurrentPage() + 1 === 1) {
                setPreviousButtonIsDisabled(true);
            } else {
                setPreviousButtonIsDisabled(false);
            }
        }
        //So pagination buttons aren't accidentally shown when there are no rows
        if(gridRef.current?.api?.paginationGetTotalPages() === 0){
            setNextButtonIsDisabled(true);
            setPreviousButtonIsDisabled(true);
        }
    }

    //------ external filter (active vs. all)---
    const activeExternalFilter = useSelector(state => state.grid.activeExtFilter)
    const isInitialMount = useRef(true);

    // When we have nothing in session storage, we need to determine the initial filter with following logic.
    // it is not enough to just dispatch the state, and hope the state update will be captured by the useEffect
    // (the one with activeExternalFilter as its dependency), and apply the changes, because in the initialization
    // gridRef.current is undefined, it can not apply the changes.
    const determineInitialExtFilter = () => {
        let filterType = activeExternalFilter[`${props.gridName}${GRID_SESSION_STORAGE.EXTERNAL_FILTER_STATE}`];
        if (filterType === undefined && props.filterItems) {
            filterType = props.defaultFilterType ? props.defaultFilterType : props.filterItems[0].filterType;
            dispatch(setExternalActiveFilter({gridName: props.gridName, filterType: filterType}))
        }
        return filterType
    }
    const isExternalFilterPresent = () => {
        // priority: 1. filter set by user, which is saved in redux and session storage
        // priority 2: default filter (which is most likely "active only")
        const filterType = determineInitialExtFilter()
        // Only filter if filter not set to all and not undefined (so either for grids where filterButton is set to "All" or grids without a filterItems property)
        return filterType !== "ALL" && filterType !== undefined;
    };
     const doesExternalFilterPass = useCallback(
        (node) => {
            const filterType = determineInitialExtFilter()
            if (node.data) {
                return dataPassesFilter(node.data, filterType)
            }
            return true;
        },
        [activeExternalFilter]
    );
    const externalFilterChanged = useCallback((newValue) => {
        // after dispatching, the state is not immediately updated. That is why we need useEffect below.
        dispatch(setExternalActiveFilter({gridName: props.gridName, filterType: newValue}))
    }, []);

    useEffect(() => {
        // we only want to rerender the grid if the filter change. When the component is first mounted
        // we do not want to run it.
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            gridRef.current?.api?.onFilterChanged();
            //hideEmptyColumns(); //Commenting this out might cause columns to be hidden for no reason
        }
    }, [activeExternalFilter])
    // ------  export data entries to Excel file

    const exportExcel = () => {
        // push event for ga4
        triggerEvent('file_download', 'click', 'success', 'excel', 'grid_download_button');

        // check whether all properties are available to prevent accessing undefined
        if (gridRef && gridRef.current && gridRef.current.api && props.downloadable) {
            let allColumnIds = [];
            gridRef.current.columnApi.getColumns().forEach((column) => {
                if (!column.getId().includes("icon")) {
                    allColumnIds.push(column.getId());
                }
            });
            gridRef.current.api.exportDataAsExcel({
                fileName: props.fileName + ".xlsx",
                columnKeys: allColumnIds
            });
        }
    };
    //Before exporting CSV it checks for each cell if it starts with one of these and if so, removes them.
    const forbiddenSymbols = ['+', '-', '=', '@', "\t", "\r"]

    // ------  export data entries to CSV file
    const exportCsv = () => {
        // push event for ga4
        triggerEvent('file_download', 'click', 'success', 'csv', 'grid_download_button');

        // check whether all properties are available to prevent accessing undefined
        if (gridRef && gridRef.current && gridRef.current.api && props.downloadable) {
            let allColumnIds = [];
            gridRef.current.columnApi.getColumns().forEach((column) => {
                if (!column.getId().includes("icon")) {

                    allColumnIds.push(column.getId());
                }
            });
            gridRef.current.api.exportDataAsCsv({
                fileName: props.fileName + ".csv",
                columnKeys: allColumnIds,
                processCellCallback(params) {
                    for (let i = 0; i < forbiddenSymbols.length; i++) {
                        if (String(params.value).startsWith(forbiddenSymbols[i])) {
                            //console.log("found forbidden symbol: " + forbiddenSymbols[i] + " in cell: " + params.value)
                            return ""
                        }
                    }
                    return params.value;
                }
            });
        }
    };
    // -------save the state of all column filters to redux
    const [filterChips, setFilterChips] = useState()
    const onFilterChanged = (event) => {
        let filterModel = gridRef.current.api.getFilterModel();
        setFilterChips(filterModel)
        dispatch(setColumnFilterModel({gridName: props.gridName, model: filterModel}))
        props.onFilterChanged && props.onFilterChanged(event);
        //IF YOU WANT WANT TO CHECK FOR EMPTY COLUMNS WHENEVER A FILTER IS CHANGED OR SEARCH IS USED, UNCOMMENT THE FOLLOWING LINE
    }
    // save column sorting state changes to session storage
    const onColumnSortingChanged = (event) => {
        if (event.source === "uiColumnSorted") {
            const colState = gridRef.current.columnApi.getColumnState();
            const sortState = colState.map((state) => ({
                colId: state.colId,
                sort: state.sort,
                sortIndex: state.sortIndex,
            }));
            saveToSessionStorage(`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_SORT_STATE}`, JSON.stringify(sortState));
        }
    }

    //------ overall grid settings
    const grid_filter = useSelector(state => state.grid.columnFilterModel)
    const restoreColFilterState = () => {
        let colFilterState = grid_filter[`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_FILTER_STATE}`]
        if (colFilterState && Object.keys(colFilterState).length !== 0){
            gridRef.current?.api?.setFilterModel(grid_filter[`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_FILTER_STATE}`]);
            gridRef.current?.api?.onFilterChanged();
        }
    }
    const restoreSortingAndColumnOrder = () => {
        const grid_state = getFromSessionStorage(`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_ORDER_HIDE_STATE}`)

        if (grid_state) {
            let stateObject = JSON.parse(grid_state)
            gridRef.current?.columnApi?.applyColumnState({state: stateObject, applyOrder: true,});
        }
        const grid_state_sort = getFromSessionStorage(`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_SORT_STATE}`)
        if (grid_state_sort) {
            // if there is a preference saved, we should overwrite the default in column defs
            // e.g. the default sort in driver overview is first Name.
            // if we do not clear all sort (applying an empty one), the saved states will be added *additionally*
            gridRef.current?.columnApi?.applyColumnState({defaultState: {sort: null},});
            let stateObject = JSON.parse(grid_state_sort)
            gridRef.current?.columnApi?.applyColumnState({state: stateObject});
        }
    }
    // save column order to redux
    const onColumnOrderChanged = (event) => {
        if (event.finished && event.source === "uiColumnMoved") {
            const colState = gridRef.current.columnApi.getColumnState();
            const orderAndVisibilityState = colState.map((state) => ({
                colId: state.colId,
                hide: state.hide,
            }));
            saveToSessionStorage(`${props.gridName}${GRID_SESSION_STORAGE.COLUMN_ORDER_HIDE_STATE}`, JSON.stringify(orderAndVisibilityState));
        }
    }

    //------ restore page number
    const grid_current_page = useSelector(state => state.grid.currentPageNumber)
    const restorePageNumber = () => {
        let pageState = grid_current_page[`${props.gridName}`]
        if (pageState && pageState !== 0) {
            gridOptions.api.paginationGoToPage(pageState);
        }
    }

    const onGridReady = useCallback((props) => {
        restoreColFilterState()
        /*gridRef.current.api.sizeColumnsToFit();*/

        // the function below is needed when grid ready, because the redux does not save the states for these
        restoreSortingAndColumnOrder()
        restorePageNumber()
    },[])

    // Update row data when data changes (e.g. when a row is edited)
    const updateData = () =>{
        if (gridRef.current) {
            gridRef.current.api?.setRowData(props.data);
        }
    }

    useEffect(() => {
        if(props.onCellValueChanged){
            updateData();
        }
        //hideEmptyColumns();
    }, [props.data])

    //Add defaultFilter property to one of the filterItems
    let filterItems = props.filterItems;
    if (props.defaultFilterType && props.filterItems) {
        filterItems = props.filterItems?.map((item) => {
            if (item.props.filterType === props.defaultFilterType) {
                return {...item, isDefaultFilter: true};
            }
            return item;
        });
    }

    // ------restore the filter, external filter, column order and sorting after language change
    useEffect(() => {
        if (!isInitialMount.current) {
            restoreColFilterState()
            restoreSortingAndColumnOrder()
        }
    }, [current_language])

    // content containing:
    // * download button (only shown when property tells to)
    // * grid with all entries
    let content = (
        <div className="ag-grid-flex-container" aria-rowcount={gridRef?.current?.api?.getDisplayedRowCount()}>
            <div className="ag-grid-top-container" hidden={!props.gridTitle && !props.downloadable}>
                {props.gridTitle}
                <div className="ag-grid-download-button-container" hidden={!props.downloadable}>
                    {props.downloadContent}
                    <ButtonWithIcon text={props.downloadTextExcel} buttonStyle={"btn-primary"}
                                    clickFunc={exportExcel} data-cy="excel-download-button">
                        <DownloadIcon className="inner-icon"></DownloadIcon>
                    </ButtonWithIcon>
                    <ButtonWithIcon text={props.downloadTextCsv} buttonStyle={"btn-primary"} clickFunc={exportCsv}
                                    data-cy="csv-download-button">
                        <DownloadIcon className="inner-icon"></DownloadIcon>
                    </ButtonWithIcon>
                </div>
            </div>
            <div className="ag-grid-overall-container">
                <div className="ag-grid-above-header-container">
                    {props.headerContent ? <div className="ag-grid-above-header-container-content">
                        {props.headerContent}
                    </div> : <></>}

                    {/*List of currently in-use column filters*/}
                    {filterChips && Object.keys(filterChips).length > 0 ?
                        <GridColumnFilterList filterList={filterChips}
                                              gridRef={gridRef}
                                              gridName={props.gridName}

                        ></GridColumnFilterList> : <></>}
                    <div className="ag-grid-above-header-right-side" hidden={props.hideSearch}>
                        {/* Content to be placed in header, left from search bar*/}
                        {props.headerSideContent}

                        {/*quick filter or gloabl filter*/}
                        <div className="ag-grid-quick-filter">
                            <MagnifierIcon className="inner-icon"></MagnifierIcon>
                            <input className="input-field-outline"
                                   type="text"
                                   id={"quick-filter-text-box " + props.gridName}
                                   style={{maxWidth: "220px", paddingLeft: "2.5rem"}}
                                   onInput={onFilterTextBoxChanged}
                                   data-cy="grid-search-input"
                            />
                        </div>
                        {/*external filter*/}
                        {props.filterItems ? <div className="ag-grid-external-filter">
                            <FilterButtonGroup items={filterItems} onFilterChange={externalFilterChanged}
                                               selectedItem={filterItems.find(item => item.props?.filterType === activeExternalFilter[`${props.gridName}${GRID_SESSION_STORAGE.EXTERNAL_FILTER_STATE}`])}/>
                        </div> : <></>}
                    </div>
                </div>

                <div id="upto-ag-grid" className="ag-theme-alpine grid-container"
                     style={{height: props.gridHeight ? props.gridHeight : ""}}>
                    <AgGridReact
                        rowData={props.data}
                        localeText={localeText}
                        ref={props.forwardedRef || gridRef}
                        columnDefs={props.columnDefs}
                        gridOptions={gridOptions}
                        defaultColDef={defaultColDef}
                        isExternalFilterPresent={isExternalFilterPresent}
                        doesExternalFilterPass={doesExternalFilterPass}
                        onGridReady={onGridReady}
                        onFilterChanged={onFilterChanged}
                        suppressPaginationPanel={true}
                        onPaginationChanged={onPaginationChanged}
                        suppressDragLeaveHidesColumns={true}
                        onSortChanged={onColumnSortingChanged}
                        onColumnMoved={onColumnOrderChanged}
                        suppressClickEdit={true}
                        onCellValueChanged={props.onCellValueChanged}
                        excelStyles={props.excelStyles}
                    >
                    </AgGridReact>
                </div>
                <div className="ag-grid-bottom-container">
                    <div className="ag-grid-bottom-text" data-cy="grid-page-info" hidden={!(totalPages >= 0)}>
                        {localeText?.page} {currentPage} {localeText?.of} {totalPages}
                    </div>
                    {   //If both buttons are disabled (if there is only one page), don't show the buttons
                        nextButtonIsDisabled && previousButtonIsDisabled ?
                            <div hidden={props.hidePagination} className="ag-grid-bottom-controls"></div>
                            :
                            <div hidden={props.hidePagination} className="ag-grid-bottom-controls">
                                <button className="btn btn-light"
                                        disabled={previousButtonIsDisabled}
                                        onClick={previousPageClick}
                                        data-cy="grid-previous-page-button"
                                >
                                    {localeText?.previousPage}
                                </button>
                                <button className="btn btn-light"
                                        disabled={nextButtonIsDisabled}
                                        onClick={nextPageClick}
                                        data-cy="grid-next-page-button"
                                >
                                    {localeText?.nextPage}
                                </button>
                            </div>
                    }

                </div>
            </div>
        </div>
    );

    return (
        <>
            {content}
        </>

    )
})

export default GridHandler