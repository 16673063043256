import { useEffect, useRef, useState, memo } from "react";
// import styling
import "./_style.scss";
import { useSelector } from "react-redux";


const SearchableDropdown = memo(({
    options,
    keyLabel,
    id,
    selectedVal,
    handleChange,
    isLoading,
    otherLabels,
    setValidSelectedVehicle,
    ...props
}) => {


    SearchableDropdown.defaultProps = {
        searchType: "VIN"
    }
    const searchType = props?.searchType;
    // The text that is currently typed into the search input field (updated on every keystroke)
    const [query, setQuery] = useState("");
    // True if the dropdown (even if it just shows "Please enter at least 6 symbols") is visible, false otherwise
    const [isOpen, setIsOpen] = useState(false);

    // True if a car was found for the entered VIN and an option WAS CLICKED, false otherwise
    const [isInputValid, setIsInputValid] = useState(undefined)

    // True if the input field was clicked and something was entered at least once (even if it was not valid), false otherwise
    const [inputFieldClicked, setInputFieldClicked] = useState(false)

    // get the labels
    const labels = useSelector(state => state.labels.currentLabels)

    // start form here
    const inputRef = useRef(null);

    function searchLabel() {
        if (searchType === "PLATENUMBER") {
            return labels?.damagesPage?.damageInfo?.enterLicensePlate;
        } else {
            return labels?.damagesPage?.damageInfo?.searchForCar;
        }
    }

    // Check if a car was found for the entered VIN and set isInputValid accordingly
    useEffect(() => {
        // Yue: I dont think this check makes sense to me anymore. I will now set input as valid as long as there is a vin
        // let matchFound= options.some(option => {
        //     return option[keyLabel]?.toLowerCase()===selectedVal?.toLowerCase()
        // })
        // if(matchFound){
        //     setIsInputValid(true)
        // }else{
        //     setIsInputValid(false)
        // }
        if (selectedVal?.length > 0) {
            setIsInputValid(true)
        } else {
            setIsInputValid(false)
        }
    }, [keyLabel, selectedVal, options]);

    // update the ui based on whether the vin in input is valid or not
    useEffect(() => {
        document.addEventListener("click", toggle);
        return () => document.removeEventListener("click", toggle);
    }, []);

    // Is called when an option/vehicle from the dropdown is clicked, updates the input field and closes the dropdown
    const selectOption = (option) => {
        setQuery(() => "");
        handleChange(option[keyLabel]);
        setIsOpen((isOpen) => !isOpen);
        if (setValidSelectedVehicle) {
            setValidSelectedVehicle(option)
        }
    };

    // Is called when the input field is clicked, opens the dropdown
    function toggle(e) {
        setIsOpen(e && e.target === inputRef.current);
    }

    // The value that is displayed in the input field, either the query or if a car has been selected then that car's VIN (selectedVal)
    const getDisplayValue = () => {
        if (query) return query;
        if (selectedVal) return selectedVal;

        return "";
    };

    // If there is no warning, show only the content (the vehicles)
    // We know that there is no warning if options doesn't have a "warning" key
    const showOnlyContent = (options) => {
        return options?.filter(
            (option) => option["warning"] === undefined
        );
    };

    // Warnings such as "no results" are also passed in as options, if there is a warning, filter options to just show warning
    const showWarning = (options) => {
        return options?.filter(
            (option) => option["warning"] !== undefined
        );
    };

    return (
        <div className="dropdown">
            <div className="control">
                <div className="selected-value">
                    {/* The input field where the user enters the VIN / Platenumber */}
                    <input
                        className={!inputFieldClicked ? "input-field-neutral" : isInputValid ? "input-field-valid" : "input-field-warning"}
                        ref={inputRef}
                        type="text"
                        value={getDisplayValue()}
                        name="searchTerm"
                        onChange={(e) => {
                            setQuery(e.target.value);
                            handleChange(e.target.value);
                            setInputFieldClicked(true)
                        }}
                        onClick={toggle}
                        placeholder={searchLabel()}
                    />
                </div>
                {/* Chevron, faces either up or down depending on whether dropdown is visible or not*/}
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
            </div>

            {/* The dropdown that shows the vehicles and errors*/}
            <div className={`options ${isOpen ? "open" : ""}`}>
                {/*If loading => show loading, if there's a Warning (in the options object) => only show warning, otherwise show all options */}
                {isLoading ? <div className="search-warning">Loading...</div> :
                    showWarning(options).length === 1 ?
                        <div className="search-warning">{showWarning(options)[0]["warning"]}</div> : showOnlyContent(options).map((option, index) => {
                            return (
                                <div
                                    onClick={() => selectOption(option)}
                                    className={`option ${option[keyLabel] === selectedVal ? "selected" : ""
                                        }`}
                                    key={`${index}`}
                                >
                                    {option[keyLabel]}
                                    {otherLabels.map(label => {
                                        return <div style={{ fontSize: "12px" }}>{option[label]}</div>
                                    })}
                                </div>
                            );
                        })}
            </div>
        </div>
    );
})

export default SearchableDropdown;
