// import styling
import "./_style.scss"


// import external
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "moment";


// import internal
import LoadingComponent from "../LoadingComponent";
import KeyInfoCard from "../KeyInfoCard";
import ButtonWithIcon from "../ButtonWithIcon";
import AlertContainer from "../AlertContainer";
import DamageGrid from "../DamageGrid";
import AddDamageModal from "../AddDamageModal";


// import helper
import { useGetDamagesQuery, useUpdateDamageMutation } from "../../redux/apiSlice/damagesApiSlice";
import { useUserPermissions } from "./DamageHooks";
import { handleErrorMessage } from "../../actions/handleErrorMessage";
import { createErrorMessage } from "../AlertContainer/alert.utils";


// import assets
import { ReactComponent as PlusIcon } from "../../assets/svg/icon-plus.svg";


const DamageOverview = (props) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { allowEdit } = useUserPermissions();

    const {
        data: damages,
        isLoading,
        isSuccess,
        error
    } = useGetDamagesQuery();

    const [createDamage, { isLoading: isCreationLoading, isError: isCreationError }] = useUpdateDamageMutation()

    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);

    const handleCreateDamage = (newDamageData) => {
    
        // Prepare request data for assigning driver to car
        const requestData = {
            "damageDate": Moment(newDamageData?.damageDate).format("YYYY-MM-DDT00:00:00+00:00"),
            "carId": newDamageData.carId,
            "vehicleAax": {
                "carId": newDamageData.carId
            },
            "messageId": "1234",
            "damageBranche": newDamageData.damageBranche,
            "insurancePolicyNumber": newDamageData.insurancePolicyNumber,
            "insuranceCompanyName": newDamageData.insuranceCompanyName,
            "description": newDamageData.description
        }

        createDamage(requestData).then((response) => {
            if (response.data) {
                navigate("/damages/" + response.data.id);
            }
            handleCloseModal();
        });
    }

    if (isLoading) {
        return <LoadingComponent show />;
    }

    // there is when there currently no damamges or we can not load them
    if (!isSuccess || damages.length === 0) {
        return (
            <>
                <div className="damage-overview-header">
                    <h1>{props.title}</h1>
                    <ButtonWithIcon
                        text={props.labels?.damagesPage?.damageInfo?.reportDamage}
                        buttonStyle="btn-primary"
                        size="sm"
                        clickFunc={handleOpenModal}
                        hidden={!allowEdit}
                    >
                        <PlusIcon />
                    </ButtonWithIcon>
                </div>
                <AddDamageModal
                    show={showModal}
                    onClose={handleCloseModal}
                    onSubmit={handleCreateDamage}
                    labels={props.labels}
                    isLoading={isCreationLoading}
                />
                {(error||damages.length === 0) && createErrorMessage(error, "", "h1", props.labels)}
            </>
        );
    }


    const mostRecentDamage = damages.reduce((prev, current) =>
        (Moment(prev.damageDate).isAfter(current.damageDate)) ? prev : current
    ).damageDate;


    return (
        <div className="damage-overview-container">
            <div className="damage-overview-header">
                <h1>{props.title}</h1>
                <ButtonWithIcon
                    text={props.labels?.damagesPage?.damageInfo?.reportDamage}
                    buttonStyle="btn-primary"
                    size="sm"
                    clickFunc={handleOpenModal}
                    hidden={!allowEdit}
                >
                    <PlusIcon />
                </ButtonWithIcon>
            </div>
            <AlertContainer
                alertStyle="alert-warning"
                show={isCreationError}
            >
                {handleErrorMessage(error, props.labels)}
            </AlertContainer>
            <KeyInfoCard
                title={props.labels?.damagesPage?.damageInfo?.mostRecentDamage}
                number={Moment(mostRecentDamage).format("DD.MM.YYYY")}
                cardStyle="card-neutral"
            />
            <DamageGrid
                damages={damages}
                labels={props.labels}
            />
            <AddDamageModal
                show={showModal}
                onClose={handleCloseModal}
                onSubmit={handleCreateDamage}
                labels={props.labels}
                isLoading={isCreationLoading}
            />
        </div>
    );
};


export default DamageOverview;